<template>
  <aside>
    <!-- Desktop -->
    <label
      for=""
      class="search lg:w-3/5 sm:w-full h-full items-center ml-20 rounded-md px-3 mb-0 hidden sm:flex"
    >
      <img class="w-5" src="@/assets/img/lupa.png" alt="Lupa" />
      <input
        v-model="typedValue"
        class="search__input bg-transparent ml-2 border-none focus:outline-none w-full focus:ring-0"
        type="text"
        placeholder="Pesquisar"
      />
      <button
        class="search__button cursor-auto bg-transparent border-none"
        @click="() => (typedValue = '')"
      >
        <CloseIcon class="w-5" />
      </button>
    </label>

    <!-- Mobile -->
    <label
      for=""
      class="search w-full h-full flex items-center rounded-md mb-0 sm:hidden"
      :class="{
        'search__input-background justify-start px-2 py-2': isExpandSearch,
        'justify-end': !isExpandSearch,
      }"
    >
      <img
        class="w-5"
        src="@/assets/img/lupa.png"
        alt="Lupa"
        @click="expandSearch"
      />
      <input
        ref="searchInput"
        v-model="typedValue"
        class="search__input bg-transparent ml-2 border-none focus:outline-none focus:ring-0"
        :class="{
          hidden: !isExpandSearch,
          'flex w-full pl-3': isExpandSearch,
        }"
        type="text"
        @blur="retractSearch"
        placeholder="Pesquisar"
      />
      <button
        class="search__button cursor-auto bg-transparent border-none"
        :class="{ hidden: !isExpandSearch, flex: isExpandSearch }"
        @click="() => (typedValue = '')"
      >
        <CloseIcon class="w-5" />
      </button>
    </label>
  </aside>
</template>

<script>
import CloseIcon from "@/assets/img/close.svg";

export default {
  name: "MainSearchBarPanel",
  components: {
    CloseIcon,
  },
  data() {
    return {
      typedValue: "",
      isExpandSearch: false,
    };
  },
  methods: {
    expandSearch() {
      this.isExpandSearch = !this.isExpandSearch;
      this.$nextTick(() => this.$refs.searchInput.focus());
      this.$emit("searchBarIsExpanded", true);
    },
    retractSearch() {
      this.isExpandSearch = !this.isExpandSearch;
      this.typedValue = "";
      this.$emit("searchBarIsExpanded", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  background-color: #5b687318;

  &__input-background {
    background-color: #5b687318 !important;
  }

  @media screen and (max-width: 767px) {
    background-color: #fff;
  }

  &__button svg {
    visibility: hidden;
    opacity: 0;
    fill: #5b6873dc;
  }

  &__input:focus ~ &__button {
    cursor: pointer !important;

    svg {
      transition: opacity 0.5s ease-in-out;
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
