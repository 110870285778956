<template>
  <aside class="account relative">
    <a
      class="cursor-pointer account__wrap flex items-center"
      @click="toggleAccount"
    >
      <div class="account__description ml-2 sm:ml-0">
        <p class="username font-bold mb-0">{{ nomeUsuario }}</p>
        <p class="occupation text-sm">Admin</p>
      </div>
      <ImageProfile class="image__profile w-11 sm:ml-2" />
    </a>
    <transition name="fade">
      <nav v-if="isOpen" class="navigation absolute right-0">
        <ul class="navigation__list text-right mb-0">
          <li
            @click="logout"
            class="navigation__list item text-sm rounded-sm px-4 cursor-pointer py-1 cursor-pointer"
          >
            Desconectar
          </li>
        </ul>
      </nav>
    </transition>
  </aside>
</template>

<script>
import ImageProfile from "@/assets/img/user.svg";
import useJwt from "@/auth/jwt/useJwt";
import { getUserData } from "@/auth/utils";

export default {
  name: "UserConfigureManager",
  components: {
    ImageProfile,
  },
  data() {
    return {
      isOpen: false,
      nomeUsuario: "",
    };
  },
  created() {
    const userData = getUserData();
    this.nomeUsuario = userData.apelido;
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageUserDataName);

      // Redirect to login page
      this.$router.push({ name: "index" });
    },
    toggleAccount() {
      const widthScreen = window.screen.width;

      if (widthScreen > 767) {
        this.isOpen = !this.isOpen;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image__profile {
  fill: #cf0209;
  flex-direction: row;
  text-align: right;

  @media screen and (max-width: 767px) {
    fill: #fff;
    flex-direction: row-reverse;
    text-align: left;
  }
}

.account__wrap {
  color: #5b6873;
  padding: 0;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    color: #fff;
    padding-top: 1.5rem !important;
    flex-direction: row-reverse;
  }
}

.account__description .occupation {
  text-align: right;
  margin: 0 !important;

  @media screen and (max-width: 767px) {
    text-align: left;
  }
}

.navigation {
  bottom: -2.5rem;
}

.navigation__list .item {
  background-color: #ececec;

  a {
    color: #5b6873;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
