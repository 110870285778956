<template>
  <nav
    class="positionMenu z-50 absolute top-0 left-0 w-full sm:relative sm:w-80 transition-all duration-500 ease-in-out px-4"
    :class="{ 'left-0': menuIsOpen, '-left-full': !menuIsOpen }"
  >
    <UserConfigureManager class="sm:hidden block" />

    <h3 class="text-white text-xl font-semibold mt-6">Menu</h3>

    <ul class="NavMaster">
      <li class="has-subnav" @click="active">
        <a class="text-nav-title cursor-pointer">
          <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            style="margin: 0 20px"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-box svg-icon feather-icon"
            data-v-0a75af2f=""
          >
            <path
              d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"
              data-v-0a75af2f=""
            ></path>
            <polyline
              points="2.32 6.16 12 11 21.68 6.16"
              data-v-0a75af2f=""
            ></polyline>
            <line x1="12" y1="22.76" x2="12" y2="11" data-v-0a75af2f=""></line>
          </svg> -->

          <p class="flex items-center justify-between w-full px-2 m-0">
            <span class="flex">
              <IconAddIcon class="icon__add w-6 mr-4" />
              Cadastros
            </span>

            <ArrowRightIcon
              class="w-5 icon__arrow-right"
              :class="{ rotate__icon: allItemRegitersActive }"
            />
          </p>
        </a>

        <router-link to="/users">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionUsers.titleUsers") }}
          </div>
        </router-link>

        <router-link to="/fornecedores">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionProviders.titleProviders") }}
          </div>
        </router-link>

        <router-link to="/prescritor">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionPrescriber.titlePrescritor") }}
          </div>
        </router-link>

        <router-link to="/farmaceuticas">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionPharmaceuticals.titlePharmaceuticals") }}
          </div>
        </router-link>

        <router-link to="/groupPermission">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionGroupPermission.titleGroupPermission") }}
          </div>
        </router-link>
        <router-link to="/ncm">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionNcm.titleNcm") }}
          </div>
        </router-link>
        <router-link to="/pbm">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionPbm.labelNav") }}
          </div>
        </router-link>

        <router-link to="/groupProducts">

          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            Grupo de Produtos
          </div>

        </router-link>

        <router-link to="/groupProducts/ensaio">

          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            Ensaio
          </div>

        </router-link>

        <router-link to="/groupProducts/farmacopeia">

          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            Farmacopeia
          </div>

        </router-link>

        <router-link to="/admcartao">
        <div
          @click="$emit('menu-is-clicked')"
          :class="allItemRegitersStyle"
          v-show="allItemRegitersActive"
        >
          Administradora de Cartão
        </div>
        </router-link>
        <!-- <router-link to="/methods/areaMethod">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionRegisters.methods") }}
          </div>
        </router-link>
        <router-link to="/dcb">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionRegisters.dcb") }}
          </div>
        </router-link>
        <router-link @click="$emit('menu-is-clicked')" to="/lab">
          <div :class="allItemRegitersStyle" v-show="allItemRegitersActive">
            {{ $t("sectionRegisters.labs") }}
          </div>
        </router-link>
        <router-link to="/classe">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionRegisters.classe") }}
          </div>
        </router-link>
        <router-link to="/siproquim/areaSiproquim">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionSiproquim.labelNav") }}
          </div>
        </router-link>
        <router-link to="/typeContact/areaTypesContacts">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionTypeContact.labelNav") }}
          </div>
        </router-link>
        <router-link to="/pbm/areaPbm">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            {{ $t("sectionPbm.labelNav") }}
          </div>
        </router-link>
        <router-link to="/microComp/areaMicroComp">
          <div
            @click="$emit('menu-is-clicked')"
            :class="microCompStyle"
            v-show="microCompActive"
          >
            <span class="microComptext">Micro componentes</span>
          </div>
        </router-link>
        <router-link to="/admcartao/areaCard">
          <div
            @click="$emit('menu-is-clicked')"
            :class="allItemRegitersStyle"
            v-show="allItemRegitersActive"
          >
            Administrador de cartão
          </div>
        </router-link> -->
      </li>
    </ul>
  </nav>
</template>

<script>
import UserConfigureManager from "@core/layouts/default/components/UserConfigureManager.vue";
import ArrowRightIcon from "@/assets/img/arrow-right.svg";
import IconAddIcon from "@/assets/img/icon-add.svg";

export default {
  name: "Menu",
  props: ["menuIsOpen"],
  components: {
    UserConfigureManager,
    ArrowRightIcon,
    IconAddIcon,
  },
  data() {
    return {
      allItemRegitersActive: false,
      allItemRegitersStyle: "",
      microCompActive: false,
      microCompStyle: "",
    };
  },
  mounted() {
    this.$store.dispatch("groupUsers/totalGroupUsers");
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    active() {
      if (this.allItemRegitersActive) {
        this.allItemRegitersActive = false;
        this.microCompActive = false;
      } else {
        this.allItemRegitersActive = true;
        this.microCompActive = true;
        this.allItemRegitersStyle = "allItemRegiters p-2";
        this.microCompStyle = "microComp p-2";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background: #fff;
}

.rotate__icon {
  transform: rotate(90deg);
}

.icon__arrow-right,
.icon__add {
  fill: #fff;
}

.allItemRegiters {
  display: flex;
  justify-content: flex-start;
  /* margin-left: 64px; */
  width: 100%;
  color: #fff;
  font-size: 14px;
}

.positionMenu {
  width: 25%;
  min-height: 100vh;
  opacity: 1;
  background-color: #cf0209;

  @media screen and (min-width: 640px) and (max-width: 1024px) {
    width: 35%;
  }
}

.has-subnav a div {
  text-align: left;
}

.TitleMenu {
  color: white;
}

.menuContent {
  width: 20px;
}

#divider {
  margin-left: 301px;
}

.microComp {
  display: flex;
  justify-content: flex-start;
  /* margin-left: 64px; */
  width: 100%;
  color: #fff;
  font-size: 14px;
}

/* .has-subnav {
  margin-top: 20px;
} */

a {
  display: flex;
  color: #fff;
}

.welcome {
  text-align: left;
  color: #2c3e50;
}

.logof {
  width: 70%;
  margin: 0 30px;
  border-bottom: 1px solid #edf2f9;
  padding: 30px 10px 30px 0;
}

.card {
  background: red;
  width: 30%;
  float: left;
  margin: 10px 10px 10px 0;
  min-height: 120px;
  padding: 20px 10px;
  background: #fff;
  box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -webkit-box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  padding: 200;
  font-size: 21px;
  width: 100px;
}

.card span {
  text-align: left;
}

.NavMaster {
  width: 100%;
  padding: 1rem 0;
}

.text-nav-title {
  border-top: 1px #fff solid;
  border-bottom: 1px #fff solid;
  color: #fff;
  padding: 12px 0px 12px 0px;
  margin-bottom: 10px;
}

.list_custom {
  width: 20px;
  display: inline-block;
  height: 20px;
  margin-right: 1rem;
}

.chevron_custom {
  width: 40px;
  display: inline-block;
  height: 24px;
  font-size: 15px;
  float: right;
  padding-top: 5px;
}

.logout {
  top: 900px;
  left: 20px;
  color: #fff;
  border-top: 1px #fff solid;
  border-bottom: 1px #fff solid;
  width: 221px;
  height: 45px;
  padding: 12px;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

.main-menu:hover img {
  display: block;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0px;
  margin: 0px;
  padding: 0px;
  /* position: absolute; */
}

.main-menu li:hover > a,
nav.main-menu li.active > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #5fa2db;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  src: local("Titillium WebLight"), local("TitilliumWeb-Light"),
    url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff)
      format("woff");
}

.welcome h2 {
  font-size: 2rem;
  color: #2c3e50;
}

.welcome h4 {
  font-size: 1.5rem;
  color: #2c3e50;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
</style>
