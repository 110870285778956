<template>
  <div class="bg-white flex flex-col w-full">
    <Header @handle-click-menu-mobile="menuIsOpen = !menuIsOpen" />
    <section class="w-full flex relative">
      <Menu @menu-is-clicked="handleMenuClicked" :menuIsOpen="menuIsOpen" />
      <router-view class="w-full px-8 pt-4"></router-view>
    </section>
  </div>
</template>

<script>
import Header from "@/components/layouts/default/Header.vue";
import Menu from "@/components/layouts/default/Menu.vue";

export default {
  components: {
    Header,
    Menu,
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  data() {
    return {
      menuIsOpen: true,
    };
  },
  methods: {
    handleMenuClicked() {
      const windowWidth = window.screen.width;
      if (windowWidth < 768) {
        this.menuIsOpen = !this.menuIsOpen;
      }
    },
    myEventHandler(e) {
      const windowWidth = e.target.innerWidth;

      if (windowWidth > 767) {
        this.menuIsOpen = true;
      } else {
        this.menuIsOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;

  @media screen and (max-width: 1024px) {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}
</style>
