<template>
  <header
    class="grid grid-cols-12 gap-4"
    :class="{ 'p-4': !isExpandSearch, 'p-3': isExpandSearch }"
  >
    <MenuMobileIcon
      class="w-5 icon__mobile col-span-2 menu sm:hidden"
      :class="{ block: !isExpandSearch, hidden: isExpandSearch }"
      @click="$emit('handle-click-menu-mobile')"
    />
    <h1
      class="logo sm:col-span-2 items-center mb-0"
      :class="{ 'flex col-span-8': !isExpandSearch, hidden: isExpandSearch }"
    >
      <router-link to="/dashboard">
        <img src="@/assets/img/logo.png" alt="Logo FarmaFácil - PrismaFive" />
      </router-link>
    </h1>
    <MainSearchBarPanel
      @searchBarIsExpanded="
        (isExpandSearch) => {
          this.isExpandSearch = isExpandSearch;
        }
      "
      class="md:col-span-6 lg:col-span-8"
      :class="{ 'col-span-2': !isExpandSearch, 'col-span-12': isExpandSearch }"
    />
    <UserConfigureManager
      class="hidden sm:flex sm:justify-end sm:items-center md:col-span-4 lg:col-span-2"
    />
  </header>
</template>

<script>
import MenuMobileIcon from "@/assets/img/menu-mobile.svg";
import MainSearchBarPanel from "@core/layouts/default/components/MainSearchBarPanel.vue";
import UserConfigureManager from "@core/layouts/default/components/UserConfigureManager.vue";

export default {
  name: "Header",
  components: {
    MenuMobileIcon,
    MainSearchBarPanel,
    UserConfigureManager,
  },
  data() {
    return {
      isExpandSearch: false,
    };
  },
};
</script>

<style lang="scss" scoped>
header {
  border-bottom: 1px solid #5b68735d !important;
}

.logo a img {
  width: 12rem !important;

  @media screen and (max-width: 767px) {
    width: 8rem !important;
  }
}

.icon__mobile {
  fill: #5b6873 !important;
}
</style>
